<template>
  <div>
    <div class="toolbar button-items text-right">
      <b-dropdown class="card-drop ml-2" variant="light" right toggle-class="btn-sm">
        <template v-slot:button-content>
          <i class="bx bx-filter-alt align-middle mr-1"></i> {{ selected }}
        </template>
        <b-dropdown-item v-for="({ label, value }, index) in options" :key="index" @click="getPeriod(value)">{{ label }}</b-dropdown-item>
      </b-dropdown>
    </div>
    <b-modal
      id="modal-top"
      ref="modal-data-custom"
      size="sm"
      title="Período Personalizado"
      title-class="font-18"
      @ok="setPeriodCustom"
    >
      <form @submit.prevent="setPeriodCustom()">
        <div class="row mt-2">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="start">Início</label>
              <date-picker
                v-model="customData.created_start"
                :first-day-of-week="1"
                lang="pt-br"
                format="DD/MM/YYYY"
              >
              </date-picker>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="end">Fim</label>
              <date-picker
                v-model="customData.created_end"
                :first-day-of-week="1"
                lang="pt-br"
                format="DD/MM/YYYY"
              >
              </date-picker>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import DatePicker from 'vue2-datepicker'
export default {
  components: {
    DatePicker
  },
  data: () => ({
    selected: '',
    customData: {},
    options: [
      { label: 'Por hora', value: 1 },
      { label: 'Últimos 7 dias', value: 2 },
      { label: 'Este mês', value: 3 },
      { label: 'Personalizado', value: 4 }
    ]
  }),
  created () {
    this.getPeriod(1)
  },
  methods: {
    setPeriodCustom () {
      let dataInicial, dataFinal
      if (!this.customData.created_start || !this.customData.created_end) {
        this.getPeriod(1)
        return
      }
      dataInicial = this.$moment(this.customData.created_start).format('YYYY-MM-DD')
      dataFinal = this.$moment(this.customData.created_end).format('YYYY-MM-DD')
      this.emit(dataInicial, dataFinal, 1)
      this.selected = this.$moment(this.customData.created_start).format('DD/MM/YYYY') + ' - ' + this.$moment(this.customData.created_end).format('DD/MM/YYYY')
    },
    setLabel (selected) {
      this.selected = this.options.filter((o) => o.value === selected)[0].label
    },
    getPeriod (selected) {
      let dataInicial, dataFinal
      this.setLabel(selected)
      switch (selected) {
        case 1:
          dataInicial = this.$moment().format('YYYY-MM-DD')
          dataFinal = this.$moment().format('YYYY-MM-DD')
          this.emit(dataInicial, dataFinal)
          break

        case 2:
          dataInicial = this.$moment().subtract(7, 'days').format('YYYY-MM-DD')
          dataFinal = this.$moment().format('YYYY-MM-DD')
          this.emit(dataInicial, dataFinal, 1)
          break

        case 3:
          dataInicial = this.$moment().startOf('month').format('YYYY-MM-DD')
          dataFinal = this.$moment().endOf('month').format('YYYY-MM-DD')
          this.emit(dataInicial, dataFinal, 1)
          break
        case 4:
          this.$refs['modal-data-custom'].show()
      }
    },
    emit (dateInitial, dateFinale, type = null) {
      this.$emit('changeFilter', { created_start: dateInitial, created_end: dateFinale, type })
    }
  }
}
</script>
