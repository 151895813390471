<template>
  <div>
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-xl-12">
        <div class="row">
          <div class="col-lg-3">
            <div class="card mini-stats-wid">
              <div class="card-body">
                <div class="d-flex flex-wrap">
                  <div class="mr-3">
                    <p class="text-muted mb-2">
                      Pesq. Satisfação
                      <span id="tooltip-survey-dash" class="btn-outline-secondary ms-1 btn-sm link font-size-12"> ? </span>
                      <b-tooltip target="tooltip-survey-dash" triggers="hover">
                        Total de <b>Pesquisas de Satisfação</b> respondidas por Usuário
                      </b-tooltip>
                    </p>
                    <h5 class="mb-0">{{ this.survey }}</h5>
                  </div>
                  <div class="avatar-sm ml-auto">
                    <div
                      class="avatar-title bg-light rounded-circle text-primary font-size-20"
                    >
                      <i class="bx bx-happy-beaming"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card mini-stats-wid">
              <div class="card-body">
                <div class="d-flex flex-wrap">
                  <div class="mr-3">
                    <p class="text-muted mb-2">
                      Quiz
                      <span id="tooltip-quiz-dash" class="btn-outline-secondary ms-1 btn-sm link font-size-12"> ? </span>
                      <b-tooltip target="tooltip-quiz-dash" triggers="hover">
                        Total de <b>Quiz</b> respondidos em todas as salas
                      </b-tooltip>
                    </p>
                    <h5 class="mb-0">{{ this.quiz }}</h5>
                  </div>
                  <div class="avatar-sm ml-auto">
                    <div
                      class="avatar-title bg-light rounded-circle text-primary font-size-20"
                    >
                      <i class="mdi mdi-gamepad-circle-down"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card mini-stats-wid">
              <div class="card-body">
                <div class="d-flex flex-wrap">
                  <div class="mr-3">
                    <p class="text-muted mb-2">
                      Enquetes
                      <span id="tooltip-poll-dash" class="btn-outline-secondary ms-1 btn-sm link font-size-12"> ? </span>
                      <b-tooltip target="tooltip-poll-dash" triggers="hover">
                        Total de <b>Enquetes</b> respondidas em todas as salas
                      </b-tooltip>
                    </p>
                    <h5 class="mb-0">{{ this.poll }}</h5>
                  </div>
                  <div class="avatar-sm ml-auto">
                    <div
                      class="avatar-title bg-light rounded-circle text-primary font-size-20"
                    >
                      <i class="mdi mdi-poll-box-outline"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card mini-stats-wid">
              <div class="card-body">
                <div class="d-flex flex-wrap">
                  <div class="mr-3">
                    <p class="text-muted fw-medium mb-2">
                      Perguntas
                      <span id="tooltip-askings-dash" class="btn-outline-secondary ms-1 btn-sm link font-size-12"> ? </span>
                      <b-tooltip target="tooltip-askings-dash" triggers="hover">
                        Total de <b>Perguntas/Dúvidas</b> em todas as salas
                      </b-tooltip>
                    </p>
                    <h5 class="mb-0">{{ this.askings }}</h5>
                  </div>
                  <div class="avatar-sm ml-auto">
                    <div
                      class="avatar-title bg-light rounded-circle text-primary font-size-20"
                    >
                      <i class="mdi mdi-account-question"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import http from '@/http'

export default {
  components: { Loading },
  data () {
    return {
      filter: '',
      avatar: require('@/assets/images/users/user-default.png'),
      timeout: 60000,
      loading: true,
      survey: 0,
      poll: 0,
      quiz: 0,
      askings: 0
    }
  },
  methods: {
    getTotal () {
      this.loading = true
      http.get(`/admin/metrics/total-metrics-event`).then((response) => {
        this.survey = response.data.survey
        this.quiz = response.data.quiz
        this.poll = response.data.poll
        this.askings = response.data.asking
        this.loading = false
      }).then(() => {
        this.loading = false
      }).catch((error) => {
        console.error(error)
        this.loading = false
      })
    },
    reloadSync () {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.getTotal()
      }, 60000)
    }
  },
  mounted () {
    this.getTotal()
  },
  computed: {
  },
  watch: {
  }
}
</script>
