<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <dash-subscribers/>
    <dash-statistic-event/>
    <dash-online/>
    <dash-visits-ranking/>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import appConfig from '@/app.config'
import PageHeader from '@/components/page-header'
import DashSubscribers from '@/components/widgets/dash-subscribers'
import DashOnline from '@/components/widgets/dash-online'
import DashVisitsRanking from '@/components/widgets/dash-visits-ranking'
import DashStatisticEvent from '@/components/widgets/dash-statistic-event'
import { mapActions } from 'vuex'

/**
 * Dashboard Component
 */
export default {
  page: {
    title: 'Dashboard',
    meta: [
      {
        name: 'description',
        content: appConfig.description
      }
    ]
  },
  components: {
    Layout,
    PageHeader,
    DashSubscribers,
    DashOnline,
    DashVisitsRanking,
    DashStatisticEvent
  },
  data () {
    return {
      title: 'Dashboard',
      items: [{
        text: 'Dashboard'
      },
      {
        text: 'Métricas',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.GetSetting()
  },
  methods: {
    ...mapActions('event', { GetSetting: 'GetSetting' })
  }
}
</script>
