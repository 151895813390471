<template>
  <div class="row">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-xl-3 col-sm-6">
              <h4 class="card-title mb-4">Inscritos</h4>
            </div>
            <div class="col-xl-9 col-sm-6">
              <date-filter v-model="filter" @changeFilter="filtered"></date-filter>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="row">
                <div class="col-6">
                  <div class="mt-3">
                    <p class="text-muted mb-1">Total</p>
                    <h5>{{ this.subscribers.total }}</h5>
                  </div>
                </div>

                <div class="col-6">
                  <div class="mt-3">
                    <p class="text-muted mb-1">Ultima Hora</p>
                    <h5>
                      {{ this.subscribers.total_1hr }}
                      <span class="text-success font-size-13">{{ this.subscribers.total_1hr_perc }} % <i class="mdi mdi-arrow-up ml-1"></i></span>
                    </h5>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="mt-3">
                    <p class="text-muted mb-1">Ultimas 24h</p>
                    <h5>
                      {{ this.subscribers.total_24hr }}
                      <span class="text-success font-size-13">{{ this.subscribers.total_24hr_perc }} % <i class="mdi mdi-arrow-up ml-1"></i></span>
                    </h5>
                  </div>
                </div>

                <div class="col-6">
                  <div class="mt-3">
                    <p class="text-muted mb-1">Ultimos 7d</p>
                    <h5>
                      {{ this.subscribers.total_7d }}
                      <span class="text-success font-size-13"
                        >{{ this.subscribers.total_7d_perc }} % <i class="mdi mdi-arrow-up ml-1"></i
                      ></span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <apexchart
                class="apex-charts"
                height="280"
                type="line"
                dir="ltr"
                :series="series"
                :options="chartOptions"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import http from '@/http'
import DateFilter from '../date-filter'

export default {
  components: { Loading, DateFilter },
  data () {
    return {
      filter: '',
      timeout: 60000,
      loading: true,
      categories: [],
      series: [{
        data: []
      }],
      subscribers: {}
    }
  },
  methods: {
    getSubscribers () {
      this.loading = true
      http.get(`/admin/metrics/subscribers`).then((response) => {
        this.subscribers = response.data
        this.loading = false
      }).then(() => {
        this.loading = false
      }).catch((error) => {
        console.error(error)
        this.loading = false
      })
    },
    getSubscribersEvolution () {
      this.loading = true
      http.get(`/admin/metrics/subscribers/graph`, { params: this.filter }).then((response) => {
        this.series = response.data.series
        this.categories = response.data.categories
        this.loading = false
      }).then(() => {
        this.loading = false
      }).catch((error) => {
        console.error(error)
        this.loading = false
      })
    },
    filtered (data) {
      this.filter = data
      this.getSubscribersEvolution()
    },
    cancelAutoUpdate () {
      clearInterval(this.timeout)
    }
  },
  beforeDestroy () {
    this.cancelAutoUpdate()
  },
  mounted () {
    this.getSubscribers()
    this.getSubscribersEvolution()
    this.timeout = setInterval(() => {
      this.getSubscribers()
      this.getSubscribersEvolution()
    }, 60000)
  },
  computed: {
    chartOptions () {
      return {
        chart: {
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#556ee6', '#f46a6a', '#34c38f'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [3, 4, 3],
          curve: 'straight',
          dashArray: [0, 8, 5]
        },
        title: {
          text: 'Evolução - Registros',
          align: 'left',
          style: {
            fontWeight: '500'
          }
        },
        markers: {
          size: 2,
          hover: {
            sizeOffset: 6
          }
        },
        xaxis: {
          categories: this.categories
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value
            }
          }
        },
        tooltip: {
          y: [{
            title: {
              formatter: function (val) {
                return val
              }
            }
          }]
        },
        grid: {
          borderColor: '#f1f1f1'
        }
      }
    }
  },
  watch: {
  }
}
</script>
