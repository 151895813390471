<template>
  <div class="row">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="col-lg-12 mb-2 text-right">
      <span class="font-size-11 text-danger">Métricas de usuário on-line atualizadas a cada 60 segundos</span>
    </div>
    <div class="col-lg-8">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Usuários Online</h4>
          <div class="row">
            <div class="col-md-3">
              <div class="row">
                <div class="col-12">
                  <div class="mt-3">
                    <p class="text-muted mb-1">Nesse Momento</p>
                    <h5>{{ this.online.total }}</h5>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="mt-3">
                    <p class="text-muted mb-1">Ultima Hora</p>
                    <h5>{{ this.online.total_1hr }}</h5>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="mt-3">
                    <p class="text-muted mb-1">Pico Audiência</p>
                    <h5>{{ this.online.total_peak }}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-9">
              <apexchart
                ref="evolutionOnline"
                class="apex-charts"
                height="220"
                type="area"
                dir="ltr"
                :series="series"
                :options="chartOptions"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Taxa Online x Inscritos</h4>
          <div class="row">
            <div class="col-md-12">
              <apexchart
                ref="txOnline"
                class="apex-charts"
                height="240"
                type="radialBar"
                dir="ltr"
                :series="seriesOnline"
                :options="radialChartOptions"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import http from '@/http'

export default {
  components: { Loading },
  data () {
    return {
      filter: '',
      timeout: 60000,
      loading: true,
      categories: [],
      series: [{
        data: []
      }],
      seriesOnline: [],
      online: {}
    }
  },
  methods: {
    getSubscribers () {
      this.loading = true
      http.get(`/admin/metrics/users/online`).then((response) => {
        this.online = response.data
        this.loading = false
      }).then(() => {
        this.loading = false
      }).catch((error) => {
        console.error(error)
        this.loading = false
      })
    },
    getSubscribersEvolution () {
      this.loading = true
      http.get(`/admin/metrics/users/online/graph`).then((response) => {
        this.series = response.data.series
        this.categories = response.data.categories
        this.loading = false
      }).then(() => {
        this.loading = false
        // this.reloadSync()
      }).catch((error) => {
        console.error(error)
        this.loading = false
      })
    },
    getSubscribersOnline () {
      this.loading = true
      http.get(`/admin/metrics/subscribers/online`).then((response) => {
        this.seriesOnline = response.data.rate
        this.loading = false
      }).then(() => {
        this.loading = false
      }).catch((error) => {
        console.error(error)
        this.loading = false
      })
    },
    filtered (data) {
      this.filter = data
      this.getSubscribersEvolution()
    },
    cancelAutoUpdate () {
      clearInterval(this.timeout)
    }
  },
  created () {
  },
  beforeDestroy () {
    this.cancelAutoUpdate()
  },
  mounted () {
    this.getSubscribers()
    this.getSubscribersEvolution()
    this.getSubscribersOnline()
    this.timeout = setInterval(() => {
      this.getSubscribers()
      this.getSubscribersEvolution()
      this.getSubscribersOnline()
    }, 60000)
    // this.connectSocket()
  },
  computed: {
    chartOptions () {
      return {
        chart: {
          stacked: true,
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: 1000
            }
          },
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#34c38f'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 3
        },
        title: {
          text: 'Evolução - Online',
          align: 'left',
          style: {
            fontWeight: '500'
          }
        },
        grid: {
          borderColor: '#f1f1f1'
        },
        markers: {
          size: 2,
          hover: {
            sizeOffset: 6
          }
        },
        xaxis: {
          type: 'time',
          categories: this.categories
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value
            }
          }
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          }
        }
      }
    },
    radialChartOptions () {
      return {
        plotOptions: {
          size: undefined,
          inverseOrder: false,
          hollow: {
            margin: 5,
            size: '48%',
            background: 'transparent'
          },
          track: {
            show: true,
            background: '#40475D',
            strokeWidth: '10%',
            opacity: 1,
            margin: 3 // margin is in pixels
          },
          radialBar: {
            dataLabels: {
              name: {
                fontSize: '22px'
              },
              value: {
                fontSize: '16px'
              },
              total: {
                show: true,
                label: 'Online'
              }
            }
          }
        },
        labels: ['Online'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'white',
            type: 'horizontal',
            shadeIntensity: 0.8,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        colors: ['#34c38f']
      }
    }
  },
  watch: {
  }
}
</script>
