<template>
  <div>
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-xl-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Visitas - Geral</h4>
            <div class="row">
              <div class="col-6">
                <div class="mt-3">
                  <p class="text-muted mb-1">Visualizações</p>
                  <h5>{{ this.visits.pageViews }}</h5>
                </div>
              </div>
              <div class="col-6">
                <div class="mt-3">
                  <p class="text-muted mb-1">Visualizações únicas</p>
                  <h5>
                    {{ this.visits.uniqueViews }}
                  </h5>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-xl-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Visitas - Salas</h4>
            <div class="row">
              <div class="col-6">
                <div class="mt-3">
                  <p class="text-muted mb-1">Visualizações</p>
                  <h5>{{ this.visitRooms.pageViews }}</h5>
                </div>
              </div>
              <div class="col-6">
                <div class="mt-3">
                  <p class="text-muted mb-1">Visualizações únicas</p>
                  <h5>
                    {{ this.visitRooms.uniqueViews }}
                  </h5>
                </div>
              </div>
            </div>
            <hr />
            <div data-simplebar style="max-height: 221px;">
              <ul class="list-group list-group-flush">
                <li v-for="(room, i) in visitRooms.rooms" :key="i" class="list-group-item">
                  <div class="py-1">
                    <h5 class="font-size-14">
                      {{ room.room.name }}
                    </h5>
                    <p class="text-muted mb-0">
                      Visitas Totais
                       <span class="float-right">{{ room.pageViews }}</span>
                    </p>
                    <p class="text-muted">
                      Visitas Únicas
                       <span class="float-right">{{ room.uniqueViews }}</span>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4">
        <div class="card" style="min-height: 402px;">
          <div class="card-body">
            <h4 class="card-title mb-4">Ranking - Gamificação</h4>
            <div class="row">
              <div class="table-responsive">
                <table class="table table-centered">
                  <tbody>
                    <tr v-for="(rank, i) in rankings" :key="i">
                      <td width="20px"><span class="font-size-12"><b>{{ i + 1 }}</b></span></td>
                      <td width="50px" style="padding:0 !important">
                        <img
                          :src="(rank.user.data.avatar && rank.user.data.avatar !== 'undefined') ? rank.user.data.avatar : avatar"
                          class="rounded avatar-xs"
                          alt
                        />
                      </td>
                      <td width="60%">
                        <h5 class="font-size-14 m-0">
                          <h5 class="text-truncate font-size-14 mb-0">{{ rank.user.data.first_name }} {{ rank.user.data.last_name }}</h5>
                          <span class="text-muted font-size-11">{{ rank.user.data.email }}</span>
                        </h5>
                      </td>
                      <td width="20%">
                        <div>
                          <a
                            href="javascript: void(0);"
                            class="badge badge-success font-size-12"
                          >{{ rank.total }} pts</a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import http from '@/http'

export default {
  components: { Loading },
  data () {
    return {
      filter: '',
      avatar: require('@/assets/images/users/user-default.png'),
      timeout: 60000,
      loading: true,
      rankings: [],
      visitRooms: {},
      visits: {}
    }
  },
  methods: {
    getRanking () {
      this.loading = true
      http.get(`/admin/metrics/ranking?limit=5`).then((response) => {
        this.rankings = response.data.data
        this.loading = false
      }).then(() => {
        this.loading = false
      }).catch((error) => {
        console.error(error)
        this.loading = false
      })
    },
    getVisitsRooms () {
      this.loading = true
      http.get(`/admin/metrics/visits-rooms`).then((response) => {
        this.visitRooms = response.data
        this.loading = false
      }).then(() => {
        this.loading = false
      }).catch((error) => {
        console.error(error)
        this.loading = false
      })
    },
    getVisits () {
      this.loading = true
      http.get(`/admin/metrics/pageviews`).then((response) => {
        this.visits = response.data
        this.loading = false
      }).then(() => {
        this.loading = false
      }).catch((error) => {
        console.error(error)
        this.loading = false
      })
    }
  },
  mounted () {
    this.getRanking()
    this.getVisitsRooms()
    this.getVisits()
  },
  computed: {
  },
  watch: {
  }
}
</script>
